div.paginacao {
  margin: 0px auto;
  text-align: center;
  font-size: 18px;
}

div.paginacao a,
span {
  padding: 10px;
  color: #585858;
}

div.paginacao span {
}

.step {
  padding: 10px;
  color: black;
  border-radius: 10px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 0px solid #ddd;
}

.nextLink {
  padding: 10px;
  color: black;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 0px solid #ddd;
}

.prevLink {
  padding: 10px;
  color: black;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 0px solid #ddd;
}

.currentStep {
  padding: 10px;
  background-color: #16a06b;
  border-radius: 10px;
  color: white;
  border: 1px solid #4caf50;
}

.step.gap {
  display: none;
}

.step:hover:not(.active) {
  background-color: #ddd;
}
