/* @import "font-awesome-sprockets"; */
/* @import "font-awesome"; */
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700,400italic,700italic,300italic,300);
@charset "utf-8";

#centralizar_interna p img {
  height: 200px !important;
  width: 200px !important;
}

#centralizar_interna p {
  margin-left: 10px;
  color: #585858;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 15px;
  text-align: left;
  vertical-align: text-top;
  line-height: 160%;
  margin-right: 10px;
}

#centralizar_interna p a {
  /* color: #c1c159; */
  color: #107248;
}

#centralizar_interna p a:hover {
  color: #107248;
}

#centralizar_interna img {
  float: right;
  margin: 15px;
  opacity: 0.8;
  cursor: pointer;
  border: 2px solid #fff;
  border-radius: 10px;
}

#centralizar_interna img.membro {
  width: 146px;
  height: 196px;
}


#centralizar_interna img:hover {
  opacity: 1;
  border: 2px solid #cccc5e;
  border-radius: 10px;
}

#centralizar_interna ul {
  list-style-image: url(../img/marcador.png);
  margin-top: 20px;
  margin-left: 40px;
}

#centralizar_interna ul li,
#centralizar_interna ul li a {
  color: #585858;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 15px;
  line-height: 180%;
}

#centralizar_interna ul li:hover,
#centralizar_interna ul li a:hover {
  color: #107248 !important;
}

#centralizar_interna ul li a {
  color: #585858;
  /* color: #107248 !important; */
  font-size: 16px;
}

#centralizar_interna ol {
  color: #cccc5e;
  font-size: 14px;
  list-style-type: upper-roman;
  margin-left: 40px;
  padding: 0;
  margin-top: 10px;
}

#centralizar_interna ol li {
  list-style-type: upper-roman;
}

#centralizar_interna ol a {
  color: #585858;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 15px;
  list-style-type: upper-roman;
  line-height: 180%;
}

#centralizar_interna ol li:hover,
ol li a:hover {
  color: #107248;
}

content #centralizar_interna {
  background: #fff none repeat scroll 0 0;
  display: block;
  height: auto;
  margin: 0 auto;
  min-height: 470px;
  padding: 20px;
  width: 940px;
}

content #centralizar_interna .titulo_internas {
  display: block;
  height: 37px;
  width: 940px;
  border-bottom: 1px solid #8ba18b;
}

content #centralizar_interna .titulo_internas h3 {
  color: #107248;
  font: 24px "Roboto Condensed", sans-serif;
  padding: 0px;
  margin: 0px;
  vertical-align: text-top;
  float: left;
}

content #centralizar_interna .titulo_internas .acessibilidade_titulo_internas {
  float: right;
  height: 28px;
  width: 48px;
  display: flex;
  justify-content: space-between;
  margin-right: 5px;
}

content #centralizar_interna .titulo_internas .acessibilidade_titulo_internas a {
  color: #d4d462;
  opacity: 0.8;
  font-size: 24px;
}

content #centralizar_interna .titulo_internas .acessibilidade_titulo_internas a+a {
  margin-left: 10px;
}

content #centralizar_interna .titulo_internas .acessibilidade_titulo_internas a:hover {
  opacity: 1;
}

content #centralizar_interna .titulo_internas .acessibilidade_titulo_internas img {
  float: right;
  margin-left: 5px !important;
  margin-top: 5px !important;
  margin-bottom: 0px !important;
  margin-right: 0px !important;
  opacity: 0.7;
  border: none !important;
}

content #centralizar_interna .titulo_internas .acessibilidade_titulo_internas img:hover {
  opacity: 1;
}

content #centralizar_interna .conteudo_internas {
  width: 930px;
  display: block;
  width: 910px;
  padding: 15px;
  height: auto;
  min-height: 400px;
}

content #centralizar_interna .conteudo_internas p {
  color: #585858;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 15px;
}

/*Formulário contato*/
.form_contato {
  display: block;
  height: 340px;
  margin: 10px auto;
  width: 100%;
}

form label {
  display: inline-block;
  font: 15px "Roboto Condensed", sans-serif;
  color: #3d543b;
  width: 100%;
  height: auto;
  padding: 10px 20px 10px 0;
  text-align: right;
}

form input {
  width: 100%;
}

form .campo_form {
  background-image: linear-gradient(to bottom, #e5efe4, #dee8dd);
  border: 1px solid #cfd6cf;
  border-radius: 10px;
  color: #595959;
  display: inline-block;
  font-family: Tahoma;
  font-size: 10pt;
  height: 30px;
  margin: 10px 0;
  padding: 1px 5px;
  vertical-align: middle;
  width: 490px;
}

form textarea {
  margin-top: 10px;
  width: 100%;
  height: 120px;
  color: #595959;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 10pt;
  background-image: linear-gradient(to bottom, #e5efe4, #dee8dd);
  border: 1px solid #cfd6cf;
  padding: 1px 5px;
  vertical-align: top;
  border-radius: 10px;
}

.form_contato input:hover,
.form_contato input:focus,
.form_contato select {
  border-color: #107248;
}

.form_contato textarea:hover,
.form_contato textarea:focus,
.form_contato select {
  border-color: #107248;
}

.form_contato button:hover,
.form_contato button:focus {
  background: #107248;
  border: none;
}

.form_contato .bt_centraliza {
  display: inline-block;
  margin: 20px 0 0 116px;
  width: 490px;
}

.bt_centraliza .bt_form {
  background: #cccc5e;
  border: 1px solid #b7b754;
  color: #fff;
  float: right;
  margin-right: 10px;
  height: 36px;
  width: 100px;
  border-radius: 10px;
  padding: 1px 5px;
  color: #fff;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 15px;
  cursor: pointer;
}

.bt_centraliza .bt_form:hover {
  background: #107248;
  border: 1px solid #0d5b39;
}

#mensagem_envio {
  background: #f0f2d7 none repeat scroll 0 0;
  display: block;
  font: 15px "Roboto Condensed", sans-serif;
  height: auto;
  margin: 20px auto 0 253px;
  padding: 20px;
  text-align: center;
  width: 461px;
}

#mensagem_envio.ok {
  color: #107248;
}

#mensagem_envio.erro {
  color: #ec413e;
}

/*noticias*/
.noticias ul {
  margin-left: 50px !important;
}

.noticias ul li strong {
  color: #107248;
}

/*noticia*/

.data_titulo {
  color: #494949;
  font-size: 14px;
  font-style: italic;
  height: 20px;
  width: auto;
  display: block;
  margin-top: 2px;
}

.noticia p {
  margin-top: 30px;
  color: #585858;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 15px;
  margin-left: 10px;
  line-height: 160%;
  text-align: justify;
  vertical-align: text-top;
}

.noticia .img_noticia {
  font-family: "Roboto Condensed", sans-serif;
  line-height: 18px;
  margin-left: 10px;
  text-align: justify;
}

.noticia .area_imagem {
  float: left;
  height: 178px;
  margin-bottom: 4px;
  margin-right: 20px;
  width: 200px;
}

.noticia .area_imagem img {
  opacity: 0.7;
  border: solid #2f3f5d 1px;
  cursor: pointer;
  margin: 20px;
  margin-top: 0px !important;
  vertical-align: top;
  float: right;
  max-height: 164px;
  max-width: 200px;
  min-height: 100px;
  min-width: 190px;
  width: auto;
  height: auto;
  margin: 0px !important;
}

.noticia .titulo_img {
  color: #4d7362;
  float: right;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 14px;
  font-style: italic;
  margin: 40px 20px 20px;
}

.noticia .imagem {
  float: right;
  height: 124px;
  margin-bottom: 3px;
  margin-top: 8px;
  width: 190px;
}

.noticia .texto {
  font-size: 14px;
  color: #5d5d5d;
  font-family: "Roboto Condensed", sans-serif;
  color: #585858;
  font-size: 15px;
  line-height: 170%;
  text-align: justify;
  vertical-align: text-top;
  display: block;
  height: auto;
  width: auto;
}

.noticia img {
  border: 1px solid #2f3f5d;
  cursor: pointer;
  float: right;
  height: auto;
  margin: 20px;
  max-height: 200px;
  max-width: 200px;
  opacity: 0.7;
  vertical-align: top;
  width: auto;
}

.noticia img:hover {
  opacity: 1;
}

.noticia .texto a {
  color: #c1c159;
}

.noticia .texto a:hover {
  color: #107248;
}

/*voltar*/
.voltar {
  display: block;
  width: 940px;
  height: 25px;
  border-top: 1px solid #8ba18b;
  padding-top: 5px;
  margin-top: 20px;
  cursor: pointer;
}

.voltar a {
  color: #cccb32;
  opacity: 0.8;
  font-size: 19px;
  margin-top: 5px;
  vertical-align: middle;
}

.voltar a:hover {
  opacity: 1;
}

/*localizaçao*/
.local_mapa {
  display: inline-block;
  width: 100%;
  height: 484px;
  margin-top: 20px;
  margin-left: 10px;
}

/*Eventos*/
content .conteudo_internas #eventos #eventos_ano {
  display: block;
  margin-top: 10px;
}

content .conteudo_internas #eventos #eventos_ano .seta_eventos_esquerdo {
  display: inline-block;
  height: 50px;
  width: 20px;
  background: #d4d462;
  vertical-align: top;
  border-radius: 10px 0px 0px 10px;
  text-align: center;
  color: #fff;
  padding-top: 30px;
}

content .conteudo_internas #eventos #eventos_ano .seta_eventos_direito {
  display: inline-block;
  height: 50px;
  width: 20px;
  background: #d4d462;
  vertical-align: top;
  border-radius: 0px 10px 10px 0px;
  text-align: center;
  color: #fff;
  padding-top: 30px;
}

content .conteudo_internas #eventos #eventos_ano ul {
  display: inline-block;
}

content .conteudo_internas #eventos #eventos_ano ul li {
  background: #e1ebe0;
  height: 50px;
  width: 70px;
  display: inline-block;
  border: none;
  margin: 0px;
}

content .conteudo_internas #eventos #eventos_ano ul li a {
  height: 50px;
  width: 75px;
}

content .conteudo_internas #eventos #eventos_ano ul li .mes {
  font: 18px "Roboto Condensed", sans-serif;
  text-transform: uppercase;
  height: 27px;
  width: 60px;
  border-bottom: solid 1px #d3dcd2;
  margin: 0px auto;
  text-align: center;
  color: #585858;
}

content .conteudo_internas #eventos #eventos_ano ul li .ano {
  font: 16px "Roboto Condensed", sans-serif;
  text-transform: uppercase;
  width: 60px;
  margin: 0px auto;
  text-align: center;
  margin-top: 5px;
  color: #585858;
}

#eventos_ano ul li a:hover .mes {
  color: #cccc5e !important;
}

#eventos_ano ul li a:hover .ano {
  color: #585858 !important;
}

content .conteudo_internas #eventos p {
  margin-left: 0px;
}

content .conteudo_internas #eventos h5 {
  color: #13774b;
  font: 19px "Roboto Condensed", sans-serif;
  margin-bottom: 10px;
  margin-top: 25px;
}

content .conteudo_internas #eventos ul {
  margin: 0px;
}

content .conteudo_internas #eventos ul li {
  display: inline-block;
  width: 432px;
  height: 154px;
  border-top: solid 1px #737373;
  padding-top: 15px;
  padding-bottom: 15px;
}

content .conteudo_internas #eventos ul li:nth-child(odd) {
  margin-right: 40px;
}

content .conteudo_internas #eventos ul li:nth-child(even) {
  margin-right: 0px !important;
}

content .conteudo_internas #eventos ul li img {
  float: left;
  width: 150px;
  height: 150px;
  margin: 0px;
  margin-right: 10px;
}

content .conteudo_internas #eventos ul li:hover>img {
  opacity: 1;
  border: 2px solid #cccc5e;
  border-radius: 10px;
}

content .conteudo_internas #eventos ul li h6 {
  color: #128252;
  font: 16px "Roboto Condensed", sans-serif;
  margin-bottom: 5px;
}

content .conteudo_internas #eventos ul li p {
  height: 82px;
  font: 13px "Roboto Condensed", sans-serif;
  color: #3d3d3d;
}

content .conteudo_internas #eventos ul li a {
  width: 100%;
  height: 100%;
}

content .conteudo_internas #eventos ul li a i {
  /* float: right; */
  /* color: #cccc5e; */
  color: #107248;
}

content .conteudo_internas #eventos ul li a:hover>i {
  color: #13774b;
}

content .conteudo_internas #eventos ul li a:hover>img {
  border: 2px solid #cccc5e;
  border-radius: 10px;
}

/*select*/
.selecao {
  width: 225px;
  display: inline-block;
  height: 30px;
  background: url(../img/select.png) no-repeat right #e1ebe0;
  overflow: hidden;
  border-radius: 10px;
  margin-right: 10px;
}

.selecao select {
  background: transparent;
  width: 248px;
  padding: 4px;
  padding-right: 58px;
  font-size: 12px;
  font-style: italic;
  color: #585858;
  border: 0;
  height: 30px;
  cursor: pointer;
  -webkit-appearance: none;
  outline: 0;
}

.selecao select option {
  display: block;
  padding: 1px;
  outline: 0;
}

/*busca_eventos*/
.busca_eventos {
  margin-top: 10px;
}

.busca_eventos .busca input {
  line-height: 22px;
  padding: 0 21px 0 10px;
  vertical-align: top;

  width: 225px;
  display: inline-block;
  height: 30px;
  background: #e1ebe0;
  overflow: hidden;
  border-radius: 10px;
  font-size: 12px;
  font-style: italic;
  color: #585858;
  margin-right: 8px;
}

.busca_eventos .busca input:focus {
  border-color: #107248;
}

.busca_eventos .busca input:hover {
  border-color: #107248;
}

.busca_eventos form .area_botoes button {
  background: #d4d462 none repeat scroll 0 0;
  border: medium none;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  float: right;
  font-size: 10pt;
  height: 36px;
  margin-left: 5px;
  width: 80px;
  font-weight: bold;
  float: right;
}

.busca_eventos form .area_botoes button:hover {
  background: #bdd0d9;
}

.busca_eventos #formulario_contato .area_botoes {
  display: block;
  height: auto;
  width: 100%;
}

.busca_eventos .lupa {
  background: #d4d462 none repeat scroll 0 0;
  border-radius: 8px;
  cursor: pointer;
  height: 30px;
  position: absolute;
  width: 80px;
  padding: 0px;
  color: #fff;
}

.busca_eventos .lupa:hover {
  background: rgba(0, 0, 0, 0) linear-gradient(#279b69, #0d6d43) repeat scroll 0 0;
  border: 1px solid #279b69;
}

/*EVENTO*/
#centralizar_interna .evento p img {
  float: left !important;
  margin-left: 0px !important;
  margin-top: 0px !important;
}

/*Documento*/
h4 {
  color: #16a06b;
  font: 18px "Roboto Condensed", sans-serif;
  margin-bottom: 5px;
}

/*membros*/
content #centralizar_interna .conteudo_internas .membros {
  display: inline-block;
  height: auto;
  min-height: 525px;
  width: 700px;
  margin-left: 20px;
  margin-top: 18px;
}

content #centralizar_interna .conteudo_internas .membros ul {
  list-style-image: none;
  margin: 0px;
}

content #centralizar_interna .conteudo_internas .membros ul li {
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
  max-height: 230px;
  min-height: 100px;
  min-height: 100px;
  padding-bottom: 10px;
  width: 734px;
}

content #centralizar_interna .conteudo_internas .membros ul li h4 {
  text-transform: uppercase;
}

content #centralizar_interna .conteudo_internas .membros ul li .mais {
  text-align: right;
  font-size: 18px;
  color: #16a06b !important;
}

content #centralizar_interna .conteudo_internas .membros ul li .mais a {
  text-transform: uppercase;
  font-size: 16px;
  color: #16a06b;
}

content #centralizar_interna .conteudo_internas .membros ul li .mais i {
  color: #16a06b !important;
}

content #centralizar_interna .conteudo_internas .membros ul li img {
  float: left;
  height: 176px;
  width: 125px;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
}

content #centralizar_interna .conteudo_internas .membros ul li:hover>img {
  opacity: 1;
  border: 2px solid #cccc5e;
  border-radius: 10px;
}

content #centralizar_interna .conteudo_internas .membros ul li p {
  font-size: 13.4px;
  margin: 0px;
  line-height: 150%;
  min-height: 100px;
}

content #centralizar_interna .conteudo_internas .membros ul li p a {
  color: #c1c159;
  /* color: #107248; */

  font-size: 13.4px;
}

#centralizar_interna .conteudo_internas .membros p {
  width: 100%;
}

#centralizar_interna .conteudo_internas .membros .publications ul li {
  min-height: 40px;
}

#centralizar_interna .conteudo_internas .membros .publications ul li p {
  min-height: 40px;
}

/*aside membros*/
content #centralizar_interna aside.menu_membros {
  background: #ecf4f1 none repeat scroll 0 0;
  float: left;
  height: auto;
  margin-top: 18px;
  min-height: 340px;
  padding: 15px;
  width: 142px;
}

content #centralizar_interna aside.menu_membros h5 {
  color: #8fafa3;
  font: 16px "Roboto Condensed", sans-serif;
  margin-bottom: 5px;
  text-transform: uppercase;
}

content #centralizar_interna aside.menu_membros ul {
  list-style-image: none;
  margin: 0px;
}

content #centralizar_interna aside.menu_membros ul li {
  display: block;
  min-height: 30px;
  width: 100%;
  height: auto;
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: solid 1px #daeae4;
}

/*aside membros*/
content #centralizar_interna aside.menu_membros ul li a {
  font-size: 14px;
  color: #949494;
}

content #centralizar_interna aside.menu_membros ul li.ativo a {
  color: #16a06b !important;
}

content #centralizar_interna aside.menu_membros h3 {
  color: #107248;
  font: 16px "Roboto Condensed", sans-serif;
}

content #centralizar_interna aside.menu_membros.chefe_lab ul li a {
  text-transform: uppercase;
}

/*publicacoes*/
content #centralizar_interna .conteudo_internas .publicacoes {
  display: block;
}

content #centralizar_interna .conteudo_internas .publicacoes ul {
  margin: 0px;
  column-count: 1;
  column-gap: 40px;
}

content #centralizar_interna .conteudo_internas .publicacoes ul li {
  display: inline-block;
  height: 170px;
  width: 98%;
  background: #ecf4f1;
  padding: 10px;
  vertical-align: top;
  margin-right: 13px;
  margin-top: 13px;
}

content #centralizar_interna .conteudo_internas .publicacoes ul li.oportunidade_card {
  height: 160px;
}



content #centralizar_interna .conteudo_internas .publicacoes ul li img {
  float: left;
  width: 100px;
  height: 75px;
  width: 177px;
  margin-left: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

content #centralizar_interna .conteudo_internas .publicacoes ul li:hover>img {
  opacity: 1;
  border: 2px solid #cccc5e;
  border-radius: 10px;
  height: 75px;
}

content #centralizar_interna .conteudo_internas .publicacoes ul li h4 {
  font-size: 16px;
  text-transform: uppercase;
}

content #centralizar_interna .conteudo_internas .publicacoes ul li p {
  font-size: 13.4px;
  margin: 0px;
  line-height: 150%;
  height: 105px;
}

content #centralizar_interna .conteudo_internas .publicacoes ul li .mais {
  text-align: right;
  display: block !important;
}

content #centralizar_interna .conteudo_internas .publicacoes ul li .mais a {
  color: #107248;
  font-size: 16px;
}

/*disciplina*/
content #centralizar_interna .conteudo_internas .disciplinas {
  display: flex;
  margin: 0;
}

content #centralizar_interna .conteudo_internas .documentos h3 {
  color: #107248;
  font: 24px "Roboto Condensed", sans-serif;
  padding: 0px;
  margin: 0px;
  vertical-align: text-top;
  float: left;
}

content #centralizar_interna .conteudo_internas .disciplinas .coluna1 {
  flex-basis: 90%;
}

content #centralizar_interna .conteudo_internas .disciplinas .coluna2 {
  flex-basis: 10%;
}

content #centralizar_interna .conteudo_internas .disciplinas .coluna1 .disciplina_info {
  display: flex;
}

content #centralizar_interna .conteudo_internas .disciplinas .coluna1 table tbody tr.even {
  background-color: #F2F2F2;
}

content #centralizar_interna .conteudo_internas .disciplinas .coluna1 table tbody tr.odd {
  background-color: white;
}

content #centralizar_interna .conteudo_internas .disciplinas .coluna2 img {
  margin-top: 0;
}

content #centralizar_interna .conteudo_internas .disciplinas ul {
  margin: 0px;
  column-count: 2;
  column-gap: 40px;
}

content #centralizar_interna .conteudo_internas .disciplinas ul li {
  display: inline-block;
  height: 160px;
  width: 100%;
  background: #ecf4f1;
  padding: 10px;
  vertical-align: top;
  margin-right: 13px;
  margin-top: 13px;
}

content #centralizar_interna .conteudo_internas .disciplinas ul li img {
  float: left;
  width: 100px;
  height: 75px;
  margin-left: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

content #centralizar_interna .conteudo_internas .disciplinas ul li:hover>img {
  opacity: 1;
  border: 2px solid #cccc5e;
  border-radius: 10px;
  height: 75px;
}

content #centralizar_interna .conteudo_internas .disciplinas ul li h4 {
  font-size: 16px;
  text-transform: uppercase;
}

content #centralizar_interna .conteudo_internas .disciplinas ul li p {
  font-size: 13.4px;
  margin: 0px;
  line-height: 150%;
  height: 105px;
}

content #centralizar_interna .conteudo_internas .disciplinas ul li .mais {
  text-align: right;
  display: block !important;
}

content #centralizar_interna .conteudo_internas .disciplinas ul li .mais a {
  color: #107248;
  font-size: 16px;
}

/*disciplina*/
content #centralizar_interna .conteudo_internas .disciplina {
  display: inline-block;
  height: auto;
  margin-left: 20px;
  min-height: 370px;
  width: 700px;
}

content #centralizar_interna .conteudo_internas .disciplina img {
  float: left;
  width: 150px;
  height: 150px;
  margin-left: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}


table {
  border-collapse: separate;
  border-spacing: 0.5em;
  width: 100%;
  font: 18px "Roboto Condensed", sans-serif;
  margin-bottom: 20px;
}

th,
td {
  /* border: 1px solid #ddd; */
  padding: 0;
  text-align: center;
}

th {
  /* background-color: #f2f2f2; */
  font-weight: bold;
}

/* tbody tr:nth-child(even) { */
/* background-color: #f9f9f9; */
/* } */



/*grupos*/
content #centralizar_interna .conteudo_internas .grupos ul li h4 {
  background: #daeae4;
  height: 22px;
  width: 902px;
  padding: 4px;
  padding-left: 8px;
}

content #centralizar_interna .conteudo_internas .grupos ul li h4 .membro_grupo {
  background: none;
  color: #16a06b;
  font: 18px "Roboto Condensed", sans-serif;
  margin-bottom: 5px;
}


content #centralizar_interna .conteudo_internas .grupos ul {
  margin: 0px;
  list-style-image: none;
}

content #centralizar_interna .conteudo_internas .grupos ul li.geral {
  display: block;
  min-height: 360px;
  margin-bottom: 30px;
}

content #centralizar_interna .conteudo_internas .grupos ul li h4 {
  margin-bottom: 0px !important;
  color: #949494;
}

content #centralizar_interna .conteudo_internas .grupos ul li ul li.menu_grupos .menu_membros {
  margin-top: 0px !important;
  height: 300px !important;
  min-height: 300px !important;
}

content #centralizar_interna .conteudo_internas .grupos ul li ul li.menu_grupos .menu_membros img {
  margin: 5px 0px;
  width: 130px;
}

content #centralizar_interna .conteudo_internas .grupos ul li ul li.menu_grupos {
  float: left;
  height: auto;
  height: 300px;
  width: 172px;
  vertical-align: top;
  margin-right: 20px;
}

content #centralizar_interna .conteudo_internas .grupos ul li ul li p {
  margin: 5px;
}

content #centralizar_interna .conteudo_internas .grupos ul li ul li img {
  float: left;
  width: 143px;
  height: 120px;
  margin-left: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-bottom: 10px;
  border: none;
}

content #centralizar_interna .conteudo_internas .grupos ul li ul li img:hover {
  border: none;
}

content #centralizar_interna .conteudo_internas .grupos ul li ul li .mais {
  float: right;
  margin-right: 10px;
}

content #centralizar_interna .conteudo_internas .grupos ul li ul li .mais a {
  color: #107248 !important;
  font-size: 16px;
}



/*grupo*/
#centralizar_interna #menu_grupo {
  margin-top: -1.5%;
  /*margin-left: -1.7%;*/
  display: flex;
  flex-direction: row;
}

#centralizar_interna #menu_grupo ul {
  margin: 0px !important;
  list-style-image: none !important;
  background: #ecf4f1;
  height: 30px;
  width: 100%;
  border-radius: 0px 0px 10px 10px;
  margin: 0px;
  list-style-image: none;
}

#centralizar_interna #menu_grupo ul li {
  height: 30px;
  vertical-align: middle;
}

#centralizar_interna #menu_grupo ul li label {
  padding-left: 8px;
}

#centralizar_interna #menu_grupo ul li i {
  float: right;
  margin-right: 10px;
  vertical-align: middle;
  margin-top: 5px;
}

#centralizar_interna #menu_grupo ul li ul {
  display: none;
  height: auto;
  min-height: 50px;
  width: auto;
  min-width: 100px;
  z-index: 1;
  position: relative;
  margin: 0px;
  list-style-image: none;
}

#centralizar_interna #menu_grupo ul li:hover>ul {
  display: block;
}

#centralizar_interna #menu_grupo ul li ul li {
  padding: 3px;
  border-bottom: 1px solid #daeae4;
  padding-left: 10px;
}

#centralizar_interna #menu_grupo ul li ul li.search_menu:hover {
  cursor: pointer;
}

#centralizar_interna #menu_grupo ul li ul li:last-child {
  border: none;
}

#centralizar_interna #menu_grupo ul li ul li a {
  font-size: 13px;
}

#centralizar_interna .grupo {
  display: block;
  height: auto;
  width: 100%;
  min-height: 400px;
}

#centralizar_interna .grupo ul {
  margin: 0px;
  list-style-image: none;
  display: block;
  height: auto;
  min-height: 100px;
  margin-bottom: 30px;
}

#centralizar_interna .grupo ul li ul li {
  display: block;
  min-height: 170px;
  border-bottom: 1px solid #daeae4;
  margin-top: 15px;
}

#centralizar_interna .grupo ul li ul li:hover>img {
  opacity: 1;
  border: 2px solid #cccc5e;
  border-radius: 10px;
}

#centralizar_interna .grupo ul li ul li a {
  color: #c1c159;
}

#centralizar_interna .grupo ul li ul li .mais a {
  color: #107248 !important;
  font-size: 16px;
}

/* 
color: #107248 !important;
  font-size: 16px; */

#centralizar_interna .grupo ul li h4 {
  margin-bottom: 0px !important;
  color: #949494;
  background: #daeae4 none repeat scroll 0 0;
  height: 22px;
  padding: 4px 4px 4px 8px;
  width: 902px;
}

#centralizar_interna .grupo ul li h4.membro_grupo {
  background: none;
  color: #16a06b;
}

.grupo ul li h5 {
  color: #16a06b;
  font: 16px "Roboto Condensed", sans-serif;
}

#centralizar_interna .grupo ul li ul li:last-child {
  border: none;
}

#centralizar_interna .grupo ul li img {
  float: left;
  height: 150px;
  width: 110px;
  margin-top: 0px;
}

#centralizar_interna .grupo ul li p {
  font-size: 13px;
  margin-top: 10px;
}

#centralizar_interna .grupo ul li a {
  font-size: 13px;
}

#centralizar_interna .grupo ul li .mais {
  float: right;
}

table.oportunidade_arquivos tbody tr.even {
  background-color: #F2F2F2;
}

table.oportunidade_arquivos tbody tr.odd {
  background-color: white;
}