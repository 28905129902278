@charset "utf-8";
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700,400italic,700italic,300italic,300);

a i {
  font-family: FontAwesome !important;
}

*,
div,
ul,
ol,
li,
a,
img,
form,
input,
select,
textarea,
button {
  margin: 0;
  padding: 0;
  outline: 0 !important;
  list-style-type: none;
  text-decoration: none;
  border: none;
  font-family: "Roboto Condensed", sans-serif;
}

*,
p,
ul,
li,
ol,
h1,
h2,
h3,
h4,
h5,
h6,
strong,
a {
  font-family: "Roboto Condensed", sans-serif;
  padding: 0px;
  margin: 0px;
}

/*body*/
body {
  background: #e3eae3;
  padding: 0px;
  margin: 0px;
  font-family: "Roboto Condensed", sans-serif;
}

/*fim body*/

/*top*/
.top {
  display: block;
  width: 100%;
  height: 35px;
  background: -webkit-linear-gradient(#545454,
      #272727);
  /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(#545454, #272727);
  /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(#545454,
      #272727);
  /* For Firefox 3.6 to 15 */
  background: linear-gradient(#545454, #272727);
  /* Standard syntax */
  padding: 0px;
  margin: 0px;
}

/*fim top*/

/*header*/
header {
  background-image: url("../img/bg_header.png");
  display: block;
  height: 200px;
  margin: 0px auto;
  width: 960px;
}

header .logo {
  display: inline-block;
  float: left;
  height: auto;
  width: 150px;
}

header .logo img {
  display: inline-block;
  float: left;
  padding: 0px;
  margin-top: 0px;
  height: 155px;
  width: 150px;
}

header #header_busca_idioma .idiomas {
  display: block;
  padding: 0px;
  margin-top: 0px;
  height: 35px;
  width: 170px;
  background: #afc3ad;
  margin-left: 126px;
  border-radius: 0px 0px 8px 8px;
  font-size: 15px;
  margin-bottom: 0px !important;
  font-family: "Roboto Condensed", sans-serif;
}

header #header_busca_idioma .idiomas .fa-chevron-down {
  font-size: 14px;
  color: #575757;
  margin-left: 20px;
}

header #header_busca_idioma .idiomas li ol {
  display: none;
  background: url(../img/ativo_idiomas02.png) no-repeat;
  height: 90px;
  width: 170px;
  /* margin-top: -28px; */
  margin: auto;
  position: absolute;
  z-index: 100;
}

header #header_busca_idioma .idiomas:hover>li ol {
  display: block;
}

header #header_busca_idioma .idiomas li ol li img {
  margin-left: 3px;
  margin-right: 2px;
  opacity: 1;
}

header #header_busca_idioma .idiomas li ol li a .opcoes_idomas_texto {
  color: #575757;
  display: inline-block;
  font-size: 15px;
  font-style: normal;
  margin: 0;
  padding: 7px 10px 3px;
}

header #header_busca_idioma .idiomas li ol li a .opcoes_idomas_texto:hover {
  color: #2c2c2c;
}

#header_busca_idioma {
  float: right;
  height: auto;
  width: 297px;
}

#header_busca_idioma .busca_header {
  display: inline-block;
  height: 100px;
  width: 300px;
  float: right;
}

header #header_busca_idioma .idiomas h5 {
  color: #575757;
  font-size: 16px;
  display: inline-block;
  padding: 0px;
  margin: 0px;
  padding-left: 10px;
  padding-top: 5px;
  padding-right: 10px;
  padding-bottom: 6px;
}

header #header_busca_idioma .idiomas .link {
  display: inline-block;
  height: 20px;
  width: 50px;
}

header #header_busca_idioma .idiomas a:hover>h5 {
  color: #2c2c2c;
  cursor: pointer;
}

header #header_busca_idioma .idiomas img {
  float: right;
  margin-top: 8px;
  margin-right: 10px;
  opacity: 0.5;
}

header #header_busca_idioma .idiomas img:hover {
  opacity: 1;
}

header #header_busca_idioma .logo_ufrn_comemorativa {
  width: 303px;
  height: 91px;
  margin-top: 10%;
}

#header_busca_idioma .busca_header .busca input {
  background: none repeat scroll 0 0 #f3f3f3;
  border: 1px solid #adadad;
  border-radius: 7px;
  color: #999999;
  font-family: Arial;
  font-size: 9pt;
  height: 33px;
  line-height: 22px;
  padding: 0 21px 0 10px;
  width: 230px;
  margin-top: 50px;
  margin-right: 4px;
}

#header_busca_idioma .busca_header .busca input:focus {
  border-color: #107248;
}

#header_busca_idioma .busca_header .busca input:hover {
  border-color: #107248;
}

#header_busca_idioma .busca_header form .area_botoes button {
  background: none repeat scroll 0 0 #2f3f5d;
  border: medium none;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  float: right;
  font-family: "Raleway", Tahoma, Verdana, Helvetica, sans-serif;
  font-size: 10pt;
  height: 36px;
  margin-left: 5px;
  width: 80px;
  font-weight: bold;
  float: right;
}

#header_busca_idioma .busca_header form .area_botoes button:hover {
  background: #bdd0d9;
}

#header_busca_idioma .busca_header #formulario_contato .area_botoes {
  display: block;
  height: auto;
  width: 100%;
}

#header_busca_idioma .busca_header .lupa {
  background: rgba(0, 0, 0, 0) linear-gradient(#545454, #272727) repeat scroll 0 0;
  border: 1px solid #545454;
  border-radius: 8px;
  cursor: pointer;
  height: 33px;
  position: absolute;
  width: 33px;
  margin-top: 50px;
  padding: 0px;
  /*font-family:FontAwesome !important;*/
}

#header_busca_idioma .busca_header .lupa:hover {
  background: rgba(0, 0, 0, 0) linear-gradient(#279b69, #0d6d43) repeat scroll 0 0;
  border: 1px solid #279b69;
}

/*fim header*/

/*nav*/

nav {
  clear: both;
  display: block;
  width: 998px;
  height: 42px;
  background: -webkit-linear-gradient(#279b69,
      #0d6d43);
  /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(#279b69, #0d6d43);
  /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(#279b69,
      #0d6d43);
  /* For Firefox 3.6 to 15 */
  background: linear-gradient(#279b69, #0d6d43);
  /* Standard syntax */
  height: 40px;
  width: 100%;
}

nav a:hover {
  text-decoration: none;
  color: #1c6dc8;
}

nav #centralizar {
  padding: 0px;
  margin: 0px;
  height: 40px;
  display: block;
  margin: 0px auto;
  width: 960px;
}

nav #centralizar ul {
  display: block;
  width: auto;
  height: 40px;
}

nav #centralizar ul li {
  float: left;
  width: auto;
  height: 24px;
  padding: 8px 1%;
  font-size: 13pt;
  color: #787878;
}

nav #centralizar ul li label {
  display: inline-block;
  height: 20px;
  width: auto;
}

nav #centralizar ul li:first-child {
  margin-left: 8px;
}

nav #centralizar ul li:last-child {
  margin-right: 0;
}

nav #centralizar ul li>label {
  color: #e9e9c8;
  cursor: pointer;
  display: inline-block;
  height: 20px;
  min-width: 56px;
  vertical-align: top;
  width: auto;
}

nav #centralizar ul li label a {
  color: #e9e9c8;
}

nav #centralizar ul li label:hover>a {
  color: #cccb32;
}

nav #centralizar ul li:hover>label {
  color: #cccb32;
}

nav #centralizar ul li:hover>label ol {
  display: block;
}

nav #centralizar ul li .separador {
  display: inline-block;
  width: 1px;
  height: 20px;
  background: #b8b8ae left top;
  margin-right: 22px;
  margin-left: 22px;
  margin-top: 2px;
  vertical-align: top;
}

nav #centralizar ul li label ol {
  background: #0c6b41 none repeat scroll 0 0;
  border-radius: 0 0 10px 10px;
  /*border-top: 1px solid #269766;*/
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.04);
  display: none;
  height: auto;
  margin: 10px 0 0 -6px;
  position: absolute;
  width: auto;
  z-index: 100;
  min-width: 193px;
  border-top: solid 1px #2da571;
}

nav #centralizar ul li label ol li {
  float: none;
  border: none;
  text-shadow: none;
  display: block;
  height: auto;
  width: auto;
  padding: 10px;
  border-bottom: 1px dashed #13955a;
  font-size: 10pt;
  margin-left: 0px !important;
}

nav #centralizar ul li label ol li ul {
  background: #0c6b41 none repeat scroll 0 0;
  border-radius: 0 10px 10px;
  /*border-top: 1px solid #269766;*/
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.04);
  display: none;
  height: auto;
  position: absolute;
  width: auto;
  z-index: 100;
  margin: -27px 0 0 183px !important;
  min-width: 140px;
}

nav #centralizar ul li label ol li:hover>ul {
  display: block;
}

nav #centralizar ul li label ol li:last-child {
  border: none;
}

nav #centralizar ul li>a {
  background: none;
  color: #ccc;
}

nav #centralizar ul li>a:hover {
  color: #cccb32;
}

nav #centralizar ul li label ol li ul li ol {
  background: #0c6b41 none repeat scroll 0 0;
  border-radius: 0 10px 10px;
  /*border-top: 1px solid #269766;*/
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.04);
  display: none !important;
  height: auto;
  position: absolute;
  width: auto;
  z-index: 100;
  margin: -30px 0 0 130px !important;
  min-width: 140px;
}

nav #centralizar ul li label ol li ul li:hover ol {
  display: block !important;
}

nav #centralizar {
  display: block;
}

/*Content*/
content {
  display: block;
  height: auto;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 50px;
}

content #centralizar {
  display: block;
  margin: 0px auto;
  width: 960px;
  height: auto;
  margin-top: 40px;
  margin-bottom: 40px;
}

/*slide_noticias*/

content #centralizar .slide_noticias {
  float: left;
  width: 960px;
  height: 370px;
  margin-bottom: 30px;
  background: #cccc5e;
  border-radius: 10px;
}

content #centralizar .slide_noticias .imagem_maior {
  height: 340px;
  width: 770px;
  float: left;
}

content #centralizar .slide_noticias .imagem_maior a {
  height: 100%;
  width: 100%;
}

content #centralizar .slide_noticias .imagem_maior img {
  height: 340px;
  width: 770px;
  border-radius: 10px;
  margin: 10px;
}

content #centralizar .slide_noticias .imagem_maior a:hover>.titulo_img_maior {
  opacity: 1;
}

content #centralizar .slide_noticias .imagem_maior .titulo_img_maior {
  background: #000;
  opacity: 0.7;
  height: 50px;
  width: 750px;
  position: relative;
  z-index: 1;
  margin-top: -80px;
  border-radius: 0px 0px 10px 10px;
  margin-left: 10px;
  color: #ccc;
  padding: 10px;
}

content #centralizar .slide_noticias .imagem_maior .titulo_img_maior p {
  opacity: 1 !important;
  color: #fff;
}

content #centralizar .slide_noticias .mais_noticias {
  float: right;
  width: 155px;
  height: 340px;
  padding-right: 12px;
  padding-bottom: 10px;
  padding-top: 10px;
}

content #centralizar .slide_noticias .mais_noticias ul {
  margin-top: -6px;
}

content #centralizar .slide_noticias .mais_noticias .setas_slide {
  display: block;
  width: 100%;
  margin: 0 auto;
  font-size: 21px;
  text-align: center;
  color: #d8e2d7;
  height: 18px;
}

content #centralizar .slide_noticias .mais_noticias li {
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: -5px;
}

content #centralizar .slide_noticias .mais_noticias li img {
  width: 153px;
  height: 63px;
  border-radius: 10px;
  border: solid 2px #cccc5e;
  opacity: 0.8;
}

content #centralizar .slide_noticias .mais_noticias li img:hover,
content #centralizar .slide_noticias .mais_noticias li.ativo img {
  opacity: 1;
  border: solid 2px #d8e2d7;
}

/*index_noticia*/
content #centralizar #index_noticia {
  display: inline-block;
  float: left;
  height: 1080px;
  width: 460px;
  background: #fff;
  margin: 0px;
  margin-bottom: 30px;
}

/*titulo_midia*/

content #centralizar #index_noticia .data_index_noticias {
  text-align: left;
  font-size: 12px;
  font-weight: normal;
}

content #centralizar #index_noticia .titulo_index_noticias {
  display: block;
  height: 42px;
  width: 470px;
  background: url(../img/titulo_noticia.png) top no-repeat;
  border-radius: 0px 10px 10px 0px;
  padding: 0px;
  margin: 0px;
  vertical-align: top;
}

content #centralizar #index_noticia .titulo_index_noticias h3 {
  color: #fff;
  font-size: 22px;
  padding: 8px 41px 8px 8px;
  text-transform: uppercase;
}

/*li_01*/
/*content #centralizar #index_noticia ul{
		padding:15px;	
	}*/
content #centralizar #index_noticia ul {
  height: 500px;
  padding: 15px;
}

content #centralizar #index_noticia ul li {
  color: #494949;
  display: inline-block;
  font-size: 14px;
  height: 110px;
  margin-bottom: 20px !important;
  text-align: left;
  vertical-align: text-top;
  width: 430px;
  cursor: pointer;
}

content #centralizar #index_noticia ul li a {
  color: #494949;
  text-decoration: none;
}

content #centralizar #index_noticia ul li img {
  display: inline-block;
  float: left;
  margin-right: 10px;
  border: solid 2px #eef1ee;
  height: 70px;
  width: 177px;
  opacity: 0.7;
}

content #centralizar #index_noticia ul li:hover img {
  border: solid 2px #cccc5e;
  opacity: 1;
}

content #centralizar #index_noticia ul li strong {
  font-size: 16px;
  color: #626262;
  margin-bottom: 7px;
  display: block;
  padding: 0px;
  vertical-align: text-top;
  background: #eef1ee;
  padding: 3px;
  padding-left: 7px;
}

content #centralizar #index_noticia ul li.texto_02 strong {
  padding: 3px;
  padding-right: 7px;
}

/*li_02*/
content #centralizar #index_noticia ul .texto_02 {
  color: #494949;
  display: inline-block;
  font-size: 14px;
  height: 110px;
  margin-bottom: 0;
  text-align: right;
  vertical-align: text-top;
  width: 430px;
  margin-bottom: 20px !important;
  cursor: pointer;
}

content #centralizar #index_noticia ul .texto_02 a {
  color: #494949;
}

content #centralizar #index_noticia ul .texto_02 img {
  display: inline-block;
  float: right;
  margin-left: 10px;
  margin-right: 0px !important;
  border: solid 2px #eef1ee;
  height: 101px;
  opacity: 0.7;
}

content #centralizar #index_noticia ul .texto_02:hover>img,
content #centralizar #index_noticia ul li:hover>img {
  border: solid 2px #cccc5e;
  opacity: 1;
}

content #centralizar #index_noticia ul .texto_02:hover>a {
  color: #cccc5e;
}

content #centralizar #index_noticia ul li:hover>a {
  color: #cccc5e;
}

content #centralizar #index_noticia ul .texto_02 strong {
  font-size: 16px;
  color: #626262;
  display: block;
  padding: 0px;
  vertical-align: text-top;
  margin-bottom: 7px;
}

content #centralizar #painel_covid19 {
  /* display: inline-block; */
  float: right;
  /* height: 200px; */
  width: 470px;
  background: #fff;
  margin-right: 10px;
  margin-top: -30px;
  /* margin-bottom: 30px; */
}

content #centralizar #painel_covid19 .titulo_index_noticias {
  display: block;
  height: 42px;
  width: 470px;
  background: url(../img/titulo_noticia.png) top no-repeat;
  border-radius: 0px 10px 10px 0px;
  padding: 0px;
  margin: 0px;
  vertical-align: top;
}

content #centralizar #painel_covid19 .titulo_index_noticias h3 {
  color: #fff;
  font-size: 22px;
  padding: 8px 41px 8px 8px;
  text-transform: uppercase;
}

content #centralizar #painel_covid19 ul {
  /* height: 500px; */
  padding: 15px;
}

content #centralizar #painel_covid19 ul li {
  color: #494949;
  display: inline-block;
  font-size: 14px;
  height: 110px;
  margin-bottom: 20px !important;
  text-align: left;
  vertical-align: text-top;
  width: 430px;
  cursor: pointer;
}

/*leia_mais*/
content #centralizar .mais {
  float: right;
  height: 35px;
  margin-left: 15px;
  margin-right: 15px;
  width: 70px;
  vertical-align: central;
  margin-bottom: 0;
}

content #centralizar .mais:hover>a {
  color: #13774b;
  cursor: pointer;
}

content #centralizar .mais a {
  float: right;
  /* color: #cccc5e; */
  color: #13774b;
  font-size: 16px;
  height: auto;
  margin: 0px;
  padding: 0px;
  vertical-align: central;
}

content #centralizar .mais {
  vertical-align: central;
  font-size: 18px;
}

/*eventos e midia*/
content #centralizar .midia,
content #centralizar .eventos {
  background: white;
  /* background: rgba(0, 0, 0, 0) url("../img/bg_publicacoes.png") repeat scroll 0 0; */
  background-size: 100%;
  float: right;
  height: 285px;
  padding: 15px;
  margin: 10px;
  width: 440px;
}

content #centralizar .midia .mais {
  margin-right: 0px;
}

content #centralizar .eventos .mais {
  margin-right: 0px;
  /* margin-top: 20%; */
}

/*midia*/
content #centralizar .midia .conteudo_midia {
  color: #494949;
  display: block;
  font-size: 14px;
  height: 230px;
  padding: 15px;
  /* vertical-align: text-top; */
  width: 440px;
  text-align: justify;
}

content #centralizar .midia ul {
  margin-bottom: 20px;
}

content #centralizar .midia li a {
  color: #494949;
  margin-left: 20px;
  width: 96%;
  display: block;
  height: auto;
}

content #centralizar .midia li {
  margin-bottom: 17px;
}

content #centralizar .midia strong,
content #centralizar .midia li a i {
  color: #13774b;
  font-size: 16px;
}

content #centralizar .midia a:hover>strong,
content #centralizar .midia li a:hover>i {
  color: #cccc5e;
}

content #centralizar .midia .conteudo_midia .data_midia {
  padding: 0px;
  display: block;
  margin: 0px;
  margin-bottom: 10px;
  font-family: "Roboto Condensed", sans-serif;
}

content #centralizar .midia .paginacao {
  display: block;
  height: 20px;
  width: 440px;
  text-align: center;
  margin-top: 25px;
}

content #centralizar .midia {
  margin-top: 30px;
  /* margin-top: 0px; */
  height: 450px;
}

content #centralizar .midia .titulo_index_midia,
content #centralizar .eventos .titulo_index_eventos {
  background: rgba(0, 0, 0, 0) url("../img/linha_titulo_02.png") no-repeat scroll right bottom;
  display: block;
  height: 30px;
  margin-bottom: 15px;
  padding-left: 30px;
  width: 410px;
}

content #centralizar .midia .titulo_index_midia h3,
content #centralizar .eventos .titulo_index_eventos h3 {
  color: #cccc5e;
  display: block;
  font-size: 22px;
  margin-left: 20px;
  text-align: right;
  text-transform: uppercase;
}

/*eventos*/
content .eventos ul {
  display: block;
  height: 176px;
  width: 440px;
  margin-bottom: 15px;
}

content .eventos ul .conteudo_calendario_direito {
  display: block;
  font-size: 14px;
  height: 82px;
  margin-bottom: 10px;
  width: auto;
}

content .eventos ul .conteudo_calendario_direito .data_evento {
  background: #cccc5e none repeat scroll 0 0;
  border-radius: 5px;
  display: inline-block;
  float: left;
  height: 75px;
  margin-top: 5px;
  width: 85px;
}

content .eventos ul .conteudo_calendario_direito:hover>.data_evento {
  background: #dbdb73 none repeat scroll 0 0;
}

content .eventos ul .conteudo_calendario_direito:hover .texto_evento>strong {
  color: #cccc5e;
}

content .eventos ul .conteudo_calendario_esquerdo:hover>.data_evento {
  background: #dbdb73 none repeat scroll 0 0;
}

content .eventos ul .conteudo_calendario_esquerdo:hover .texto_evento>strong {
  color: #cccc5e;
}

content .eventos ul .conteudo_calendario_direito .texto_evento {
  color: #494949;
  display: inline-block;
  float: right;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 20px;
  height: 60px;
  line-height: 152%;
  text-align: justify;
  width: 320px;
}

content .eventos ul .conteudo_calendario_direito strong {
  color: #13774b;
  display: block;
  font-size: 15px;
  padding: 0;
  vertical-align: text-top;
}

content .eventos ul .conteudo_calendario_esquerdo {
  display: block;
  font-size: 14px;
  height: 82px;
  margin-bottom: 10px;
  width: auto;
}

content .eventos ul .conteudo_calendario_esquerdo .data_evento {
  background: #cccc5e none repeat scroll 0 0;
  border-radius: 5px;
  display: inline-block;
  float: right;
  height: 75px;
  margin-top: 3px;
  width: 85px;
}

content .eventos ul .conteudo_calendario_esquerdo .data_evento img,
content .eventos ul .conteudo_calendario_direito .data_evento img {
  margin: 9px 5px 2px 9px;
}

content .eventos ul .conteudo_calendario_esquerdo .texto_evento {
  color: #494949;
  display: inline-block;
  float: left;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 14px;
  height: 60px;
  line-height: 152%;
  text-align: justify;
  width: 340px;
}

content .eventos ul .conteudo_calendario_esquerdo strong {
  color: #13774b;
  display: block;
  font-size: 15px;
  padding: 0;
  vertical-align: text-top;
}

content .eventos .mais {
  /* background: rgba(0, 0, 0, 0) url("../img/mais+.png") no-repeat scroll right center; */
  display: inline-block;
  float: right;
  height: 35px;
  width: 100px;
  margin-left: 15px;
  margin-right: 15px;
}

content .eventos .mais a {
  color: #cccc5e;
  float: right;
  font-size: 19px;
  height: auto;
  margin: 5px 0 0;
  padding: 0 40px 0 0;
}

content .eventos .mais:hover {
  /* background:url("../img/mais+_hover.png") no-repeat right; */
  cursor: pointer;
}

content .eventos .mais:hover>a {
  color: #13774b;
  cursor: pointer;
}

content .eventos h5 {
  color: #fff !important;
  display: block;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 22px;
  font-weight: bold !important;
  height: 24px;
  margin-top: 4px;
  text-align: center;
}

content .eventos h6 {
  color: #fff !important;
  display: block;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 22px;
  font-weight: bold !important;
  height: 20px;
  margin: 0 0 3px;
  text-align: center;
  vertical-align: text-top;
}

content .eventos .mais_documentos {
  display: block;
  line-height: 20px;
  padding-left: 15px;
  width: auto;
  font-family: Raleway;
  font-size: 16px;
  color: #2f3f5d;
  margin-bottom: 15px;
  font-weight: bold;
  float: right;
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 10px;
}

content .eventos .mais_documentos a {
  color: #2f3f5d;
}

content .eventos .mais_documentos a:hover {
  color: #c49c82;
}

content #centralizar .eventos .titulo_index_eventos p {
  color: #494949;
  font-size: 14px;
  vertical-align: text-top;
  display: inline-block;
  width: 430px;
  margin: 15px;
  height: 105px;
  text-align: justify;
}

/*banners*/
.banners {
  display: inline-block;
  width: 95%;
  height: 80px;
}

.banners .fa-chevron-left {
  float: left;
  width: 25px;
  height: 28px;
  margin-top: 26px;
  margin-bottom: 26px;
  margin-right: 10px;
  font-size: 26px;
  color: #c8ddc6;
}

.banners .fa-chevron-left:hover {
  color: #b4cfb2;
}

.banners .fa-chevron-right {
  float: right;
  width: 25px;
  height: 28px;
  margin-top: -60px;
  margin-bottom: 26px;
  margin-right: -39px;
  font-size: 26px;
  color: #c8ddc6;
  /* color: black; */
}

.banners .fa-chevron-right:hover {
  color: #b4cfb2;
}

.banners img {
  background: #fff none repeat scroll 0 0;
  border-radius: 10px;
  display: inline-block;
  height: 80px;
  margin-right: 5px;
  padding-left: 5px;
  padding-right: 5px;
  width: 128px;
  opacity: 0.7;
  border: solid 1px #fff;
}

.banners img:hover {
  opacity: 1;
  border: solid 1px #cccc5e;
}

/*fim content*/

/*footer */
.centraliza {
  width: 960px;
  margin: 0 auto;
}

footer .font_rodape {
  color: #4d544c;
  font-size: 14px;
  margin-top: 10px;
  line-height: 26px;
  vertical-align: text-top;
  display: block;
}

.font_rodape a {
  color: #31842a !important;
}

.font_rodape a:hover {
  color: #31842a !important;
  text-decoration: underline;
}

footer .rodape1 {
  display: block;
  padding: 20px 0;
  height: 149px;
  background: #c8ddc6;
}

.rodape1 .centraliza .bloco {
  display: inline-block;
  vertical-align: top;
  width: 279px;
  height: 142px;
  color: #ffffff;
  font-size: 12px;
  font-family: "Roboto Condensed", sans-serif;
  margin-top: 5px;
}

.rodape1 .centraliza .separador {
  background: #aec8ac;
  height: 151px;
  width: 1px;
  display: inline-block;
  margin-left: 27px;
  margin-right: 27px;
}

.rodape1 .centraliza .bloco strong {
  color: #3d543b;
  text-transform: uppercase;
  font-size: 18px;
  font-family: "Roboto Condensed", sans-serif;
}

.rodape1 .centraliza .bloco:last-child {
  width: 270px;
  border: none;
  margin-right: 0;
}

.centraliza .bloco .parceiros {
  display: block;
  height: auto;
  margin-top: 28px;
  width: 281px;
}

.centraliza .bloco .parceiros img {
  background: #fff none repeat scroll 0 0;
  border-radius: 10px;
  display: inline-block;
  height: 66px;
  width: 110px;
  opacity: 0.7;
  margin-right: 5px;
  border: solid 1px #ffffff;
}

.centraliza .bloco .parceiros img:hover {
  opacity: 1;
  border: solid 1px #13774b;
}

.centraliza .bloco .parceiros .fa-chevron-left {
  float: left;
  height: 19px;
  width: 17px;
  margin-top: 24px;
  margin-right: 7px;
  font-size: 20px;
  color: #fff;
}

.centraliza .bloco .parceiros .fa-chevron-left:hover {
  color: #91a48f;
}

.centraliza .bloco .parceiros .fa-chevron-right {
  float: right;
  height: 19px;
  width: 17px;
  margin-top: 24px;
  font-size: 20px;
  color: #fff;
}

.centraliza .bloco .parceiros .fa-chevron-right:hover {
  color: #91a48f;
}

.centraliza ul.social {
  width: 279px;
}

.centraliza ul.social li {
  display: inline-block;
  margin: 13px 19px 0 0;
  width: 100px;
}

ul.social li a {
  display: block;
  width: 72px;
  height: 42px;
  padding: 7px 0 0 46px;
  font-size: 14px;
  color: #4d544c;
}

ul.social li .facebook {
  background: url(../img/facebook.png) no-repeat;
  opacity: 0.6;
}

ul.social li .flick {
  background: url("../img/flick.png") no-repeat;
  opacity: 0.6;
}

ul.social li .rss {
  background: url("../img/rss.png") no-repeat;
  opacity: 0.6;
}

ul.social li .youtube {
  background: url("../img/youtube.png") no-repeat;
  opacity: 0.6;
}

ul.social li .instagram {
  background: url("../img/instagram.png") no-repeat;
  opacity: 0.6;
}

ul.social li .facebook:hover,
ul.social li .flick:hover,
ul.social li .rss:hover,
ul.social li .youtube:hover {
  opacity: 1;
}

footer {
  position: relative;
  z-index: 0;
}

footer .topo {
  background: none repeat scroll 0 0 #c8ddc6;
  border: 1px solid #91a48f;
  border-radius: 6px;
  display: block;
  height: 24px;
  margin: -15px auto -12px;
  position: relative;
  width: 40px;
}

footer .topo:hover {
  background: none repeat scroll 0 0 #afc6ad;
  border: 1px solid #9eb39d;
}

footer .fa-caret-up {
  opacity: 1;
  display: block;
  width: 100%;
  color: #91a48f;
  font-size: 23px;
  text-align: center;
}

footer .fa-caret-up:hover {
  color: #516f4e;
}

footer .rodape2 {
  background: -webkit-linear-gradient(#545454,
      #272727);
  /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(#545454, #272727);
  /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(#545454,
      #272727);
  /* For Firefox 3.6 to 15 */
  background: linear-gradient(#545454, #272727);
  /* Standard syntax */
  display: block;
  height: 32px;
  padding-bottom: 9px;
  padding-top: 9px;
}

.rodape2 .direitos,
.rodape2 .sinfo {
  color: #ffffff;
  font-size: 13px;
  font-family: "Roboto Condensed", sans-serif;
}

.direitos a:hover,
.sinfo a:hover {
  text-decoration: underline;
}

.direitos a {
  color: #fff;
  padding: 0px;
}

.rodape2 .sinfo {
  float: right;
  margin-top: -27px;
}

.sinfo a {
  font-size: 15px;
  color: #fff;
  padding: 0px;
}

.sinfo p {
  display: inline-block;
  height: 20px;
  width: auto;
  margin-top: 5px;
  vertical-align: top;
}

.sinfo a img:hover {
  opacity: 1;
}

.sinfo a img {
  opacity: 0.7;
}