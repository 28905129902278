@charset "utf-8";
/* CSS Document */
/*@import "font-awesome-sprockets";
@import "font-awesome";*/
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700,400italic,700italic,300italic,300);
/* CSS Document */

html,
body {
  height: 1%;
  background-color: #e3eae3;
}

html > body #mainHolder {
  height: auto;
}

/*top*/
top {
  display: block;
  width: 100%;
  height: 35px;
  background: -webkit-linear-gradient(
    #545454,
    #272727
  ); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(#545454, #272727); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(
    #545454,
    #272727
  ); /* For Firefox 3.6 to 15 */
  background: linear-gradient(#545454, #272727); /* Standard syntax */
  padding: 0px;
  margin: 0px;
}

top #centralizar {
  display: block;
  margin: 0px auto;
  width: 960px;
}

top #centralizar ul.acessibilidade {
  float: right;
  height: 19px;
  width: 183px;
  margin-bottom: 8px;
  margin-top: 6px;
}
top #centralizar ul.acessibilidade li {
  display: inline-block;
  margin-left: 9px;
}
top #centralizar ul.acessibilidade li a {
  color: #fff;
  font-family: FontAwesome;
  opacity: 0.7;
}
top #centralizar ul.acessibilidade li a:hover {
  opacity: 1;
}
top #centralizar .acessibilidade img:hover {
  opacity: 1;
}
top #centralizar .logo_ufrn {
  display: inline-block;
  float: left;
  height: 20px;
  width: 300px;
  margin-bottom: 5px;
  margin-top: 7px;
  opacity: 0.7;
}
top #centralizar .logo_ufrn:hover {
  opacity: 1;
}

body {
  background-color: #fff;
  margin: 0 0 0;
}

a {
  color: #259048;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

h1 {
  font-size: 18px;
  font-weight: normal;
}

.decore_neuro {
  background-color: #9c9;
  color: #259048;
  text-shadow: 1px 1px white;
  /*	border-radius: 6px;*/
  box-shadow: 3px 3px 1px #ccc;
  font-size: 16px;
  font-weight: bold;
  padding: 5px 12px;
  text-transform: uppercase;
}

.mainTitle {
  background-color: #9c9;
  color: #259048;
  text-shadow: 1px 1px white;
  /*border-radius: 6px;*/
  box-shadow: 3px 3px 1px #ccc;
  font-size: 16px;
  font-weight: bold;
  padding: 5px 12px;
  text-transform: uppercase;
  margin-bottom: 14px;
}

.decore_neuro2 {
  background-color: #bfbfbf;
  color: #000;
  padding: 5px 0;
  /*border-radius: 6px;*/
  text-align: center;
  box-shadow: 3px 3px 1px #ccc;
}

.decore_neuro2 a {
  color: black;
  font-family: din-medium, Arial, Helvetica, sans-serif;
  font-size: 13px;
  text-transform: uppercase;
}

.decore_image {
  border-radius: 8px;
  box-shadow: 3px 3px 1px #ccc;
}

h2 {
  font-weight: 100;
}

img {
  border: 0;
}

#mainHolder {
  height: 1%;
  margin: 0 auto;
  min-height: 1%;
  width: 980px;
}

#header {
  background-image: url("../img/bg_header.png");
  height: 200px;
}

#header .signature {
  font-size: 10px;
  line-height: 12px;
  margin-top: 63px;
  margin-right: 10px;
  text-align: right;
}

#contHolder {
  /*	background-image:url(../img/bgMenuVert.gif);*/
  background-repeat: repeat-y;
  clear: both;
}

#contHolder .right {
  width: 226px;
}

#tarjaPreta {
  background-color: #000;
  height: 5px;
  margin: 2px 0 5px;
}

#vertMenu {
  overflow: hidden;
  width: 226px;
  /*border-radius:6px;*/
}

#vertMenu #itemMenu {
  background-color: #bfbfbf;
  /*border-radius: 6px 6px 6px 6px;*/
  color: #555;
  padding: 3px 10px;
}

#vertMenu #itemMenu2 {
  background-color: #bfbfbf;
  color: #000;
  padding: 3px 0px;
}

#vertMenu #itemMenu2 a {
  color: #555;
  font-size: 12px;
  font-weight: normal;
  word-spacing: normal;
  text-decoration: none;
}

#vertMenu div a {
  color: #000;
  text-decoration: underline;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  word-spacing: 5px;
}

#vertMenu div a:hover {
  color: #fff;
  text-decoration: none;
}

#vertMenu .itemSubmenu {
  background-color: #7f7f7f;
  background-image: url("../img/bgXilo50.gif");
  border-bottom: 1px solid #ccc;
  padding: 7px 10px 7px 25px;
  width: 100%;
}

#vertMenu .itemSubmenu a {
  font-size: 10px;
}

#slideshow {
  /*float: left;*/
  width: 940px;
  height: 360px;
  padding: 10px 10px 10px 10px;
  margin-bottom: 30px;
  background: #cccc5e;
  border-radius: 10px;
}

#hiLi {
  height: 360px;
  width: 760px;
  overflow: hidden;
}

#hiLi #pic {
  height: 300px;
  width: 760px;
  overflow: hidden;
  border-radius: 10px 10px 0px 0px;
}

#hiLi #subTit {
  background-image: url("../img/bgBlk90.png");
  color: #fff;
  height: 40px;
  padding: 10px 15px;
  width: 730px;
  position: relative;
  left: 0;
  border-radius: 0px 0px 10px 10px;
}

.boleado {
  border-radius: 6px;
}

#hiLi #subTit h1 {
  color: #fff;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 20px;
  line-height: 20px;
  width: 710px;
  margin: 0 0 8px 0;
}

#hiLi #subTit h1 a {
  color: #fff;
}

#hiLi #subTit div {
  color: #fff;
  font-size: 12px;
  width: 710px;
  height: 90px;
}

#selecHolder {
  /*float:right;*/
}

#content_select {
  width: 170px;
  float: right;
  height: 350px;
  position: relative;
  top: -350px;
  z-index: 1;
}

#selecHolder .selected {
  opacity: 1;
}

.box {
  float: left;
  margin-right: 10px;
  /*width:210px;*/
  width: 360px;
}

.box h1 {
  background-color: #9c9;
  color: #259048;
  text-shadow: 1px 1px #fff;
  /*border-radius: 6px;*/
  box-shadow: 3px 3px 1px #ccc;
  font-size: 16px;
  font-weight: bold;
  padding: 5px 12px;
  text-transform: uppercase;
}

#box1 {
  /*width:300px;*/
  /*width:500px;*/
  width: 980px;
}

#box1 .dest div {
  /*width:205px;*/
  width: 250px;
}

#box2 .dest div {
  width: 115px;
}

#box3 {
  margin-right: 20px;
  margin-left: 0;
}

#box3 .dest div {
  width: 360px;
}

.dest {
  border-bottom: 1px dotted #000;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.dest h2 {
  color: #259048;
  font-size: 10px;
  font-weight: normal;
  font-style: italic;
  margin: 0 0 5px 0;
}

.dest div h2 a {
  font-size: 13px;
  font-style: normal;
  font-weight: bold;
}

.dest div {
  text-align: justify;
}

.entry {
  border-bottom: 1px dotted #000;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.entry h2 {
  color: #259048;
  font-size: 10px;
  font-weight: normal;
  font-style: italic;
  margin: 0 0 5px 0;
}

.entry div h2 a {
  font-size: 13px;
  font-style: normal;
  font-weight: bold;
}

.entry div {
  text-align: justify;
}

.quadro img {
  float: left;
  margin-right: 10px;
  height: 60px;
  width: 80px;
}

.dest img {
  float: left;
  margin-right: 10px;
  border-radius: 8px;
  box-shadow: 3px 3px 1px #ccc;
}

.dest div p {
  font-size: 10px;
  line-height: 14px;
  margin: 0 0 5px 0;
}

.dest div a {
  font-size: 11px;
  font-weight: bold;
}

.migalhas {
  border-bottom: 1px dotted #259048;
  color: #259048;
  font-size: 16px;
  font-family: din-light, Arial, Helvetica, sans-serif;
  font-style: italic;
  margin: 0 0 15px;
}

#team {
}

#team .dest div {
  font-size: 11px;
}

#team h1 {
  color: #259048;
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 5px;
}

#team h1 a {
  color: #259048;
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 5px;
}

#team h2 {
  background-color: #bfbfbf;
  font-size: 10px;
  padding: 3px 10px;
  color: #eee;
  text-transform: uppercase;
  font-weight: bold;
  box-shadow: 3px 3px 1px #ccc;
  font-family: din-medium, Arial, Helvetica, sans-serif;
}

#team select {
  background-color: #000;
  border: none;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
}

#team a {
  font-weight: normal;
}

.left {
  float: left;
}

.right {
  float: right;
}

.clear {
  clear: both;
}

.prov #vertMenu div {
  width: 100%;
  text-align: center;
}

.prov #vertMenu div a {
  font-size: 12px;
}