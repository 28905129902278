.quem_somos_img {
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.quem_somos_p {
  display: block;
  margin-top: 15px;
}

.quem_somos_p2 {
  display: block;
  margin-top: 10px;
}

span.green-card {
  background-color: black;
  color: white;
  padding: 2px 10px 2px 10px;
  border-radius: 5px;
  font-size: 10px;
}

span.yellow-card {
  background-color: #d4d462;
  color: white;
  padding: 2px;
  border-radius: 5px;
}

span.black-card {
  background-color: black;
  color: white;
  padding: 2px;
  padding: 2px 10px 2px 10px;
  border-radius: 5px;
}